export const About = (props) => {
  return (
    <section>
    <div id="about" className="about-section">
    <div className="container-fluid">
      <div className="min">
        <div className="col-md-6"> <img src="img/serviceimg.png" alt="kent" loading="lazy" className="img-responsive" /> </div>
        <div className="col-md-6">
          <div className="why-kent">
            <h2>Why RO Water Purifier Need Regular Service</h2>
            <p>Regular servicing of your RO water purifier is essential to maintain optimal performance, ensure safe drinking water, and extend the lifespan of the unit. Over time, filters can clog, and mineral buildup can affect efficiency, leading to compromised water quality.</p>
            <p>At RO Service Center, we provide expert service from verified technicians who are trained to handle installation, repairs, filter replacements, and ongoing maintenance. Our team uses high-quality components and follows best practices to ensure your system runs smoothly. With our commitment to excellence and customer satisfaction, you can trust us to keep your RO purifier in top condition, delivering clean, safe water for you and your family.</p>
            <a href="tel: 9912300212" onclick="return gtag_report_conversion('tel: 9912300212')" className="cls">Get in Touch →</a> </div>
        </div>
      </div>
    </div>
    </div>
    </section>
  );
};
