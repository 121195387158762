export const Amc = (props) => {
    return (
        <div className="plan" id="amc">
            <div className="container">
                <div className="jumbo-box2 cd">
                    <h2> Annual Maintenance Contract (AMC) For Domestic RO</h2>
                    <p>We believe that regular maintenance of water purifier may increase pressure on your pocket. Thus we offer various customized RO AMC plan. Water Purifier AMC plan is a contract in which our service engineer take care of your RO water purifier by providing periodic services, even cleaning of your system as well. We offer various services under AMC package, thus choose the best AMC plan for your water purifier. Our RO AMC plans are available at a comparatively low price. We strongly recommended you to buy RO AMC plans for your water purifier because regular and proper maintenance of your water purifier increases the life of the water purifier.</p>
                </div>
                <div className="abt-form text-center">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>AMC Plan 1</th>
                                    <th>AMC Plan 2</th>
                                    <th>AMC Plan 3</th>
                                    <th>AMC Plan 4</th>
                                    <th>AMC Plan 5</th>
                                    <th>AMC Plan 6</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="info">
                                    <td>Service</td>
                                    <td><img src="img/sign/mark.png" alt="available" /></td>
                                    <td><img src="img/sign/mark.png" alt="available" /></td>
                                    <td><img src="img/sign/mark.png" alt="available" /></td>
                                    <td><img src="img/sign/mark.png" alt="available" /></td>
                                    <td><img src="img/sign/mark.png" alt="available" /></td>
                                    <td><img src="img/sign/mark.png" alt="available" /></td>
                                </tr>
                                <tr className="success">
                                    <td>Filters</td>
                                    <td><img src="img/sign/cross.png" alt="not available" /></td>
                                    <td><img src="img/sign/cross.png" alt="not available" /></td>
                                    <td><img src="img/sign/mark.png" alt="available" /></td>
                                    <td><img src="img/sign/mark.png" alt="available" /></td>
                                    <td><img src="img/sign/mark.png" alt="available" /></td>
                                    <td><img src="img/sign/mark.png" alt="available" /></td>
                                </tr>
                                <tr className="warning">
                                    <td>Membrane</td>
                                    <td><img src="img/sign/cross.png" alt="not available" /></td>
                                    <td><img src="img/sign/cross.png" alt="not available" /></td>
                                    <td><img src="img/sign/cross.png" alt="not available" /></td>
                                    <td><img src="img/sign/mark.png" alt="available" /></td>
                                    <td><img src="img/sign/mark.png" alt="available" /></td>
                                    <td><img src="img/sign/mark.png" alt="available" /></td>
                                </tr>
                                <tr className="danger">
                                    <td>Electrical Parts</td>
                                    <td><img src="img/sign/cross.png" alt="not available" /></td>
                                    <td><img src="img/sign/mark.png" alt="available" /></td>
                                    <td><img src="img/sign/cross.png" alt="not available" /></td>
                                    <td><img src="img/sign/cross.png" alt="not available" /></td>
                                    <td><img src="img/sign/mark.png" alt="available" /></td>
                                    <td><img src="img/sign/mark.png" alt="available" /></td>
                                </tr>
                                <tr className="primary">
                                    <td>Faulty Parts</td>
                                    <td><img src="img/sign/cross.png" alt="not available" /></td>
                                    <td><img src="img/sign/cross.png" alt="not available" /></td>
                                    <td><img src="img/sign/cross.png" alt="not available" /></td>
                                    <td><img src="img/sign/cross.png" alt="not available" /></td>
                                    <td><img src="img/sign/cross.png" alt="not available" /></td>
                                    <td><img src="img/sign/mark.png" alt="available" /></td>
                                </tr>
                                <tr className="warning">
                                    <td>Price</td>
                                    <td>Rs. 999</td>
                                    <td>Rs. 2500</td>
                                    <td>Rs. 2450</td>
                                    <td>Rs. 4000</td>
                                    <td>Rs. 5000</td>
                                    <td>Rs. 6000</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <h3>Terms &amp; Conditions</h3>
                1. Contract period 12 months. <br />
                2. Menbrance one time change under AMC. <br />
                3. Cabinet &amp; Tab no covered in AMC <br />
                4. Three Periodical services (on demand) any additional visit during AMC period by intimation. <br />
                5. AMC Plans may be vary according to brand and condition of water purifier. <br />
                6. We use only Genuine Spare Parts. </div>
        </div>
    )
}