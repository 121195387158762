import React from 'react';

export const Header = (props) => {
  return (
    <header id='header' className='banner'>
          <div className='bannercontain'>
            
        
      </div>
    </header>
  )
}
