import { useState, useEffect } from "react";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import { Navigation } from "./components/navigation";
import "./App.css";
import { Returnypolicy } from "./components/pages/returnpolicy";
import { TermsCondition } from "./components/pages/termscondition";
import { Faqp } from "./components/pages/faqp";
import { Privacypolicy } from "./components/pages/privacypolicy";
import { Helpcenter } from "./components/pages/helpcenter";
import Crous from './components/crous'
import { Wedo } from "./components/wedo";
import { About } from "./components/about";
import { Achivements } from "./components/achivements";
import { Amc } from "./components/amc";
import { Footern } from "./components/footern";
import { Footer } from "./components/footer";
import { Header } from "./components/header";




export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});



const Home = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);
    
  
    return (
      <div>
      <Header />
      <Wedo />
      <About />
      <Achivements />
      <Amc />
     
      </div>
    );
  
}


export default Home;
