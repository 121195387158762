import SimpleImageSlider from "react-simple-image-slider";

const images = [
  { url: "img/banners/faq.png" },
  { url: "img/banners/helpc.png" },
  { url: "img/banners/privacy.jpg" },
  { url: "img/banners/return.png" },
  { url: "img/banners/terms.png" },
  { url: "img/banneraqa.png" },
  { url: "img/repairbanner.png" },
];

const Crous = () => {
  return (
    <div id="crous" style={{display:'flex'}}>
      <SimpleImageSlider
        width={'100%'}
        height={504}
        images={images}
        showBullets={true}
        showNavs={true}
        slideDuration={0.5}
        autoPlay={0.5}
      />
    </div>
  );
}
export default Crous;