export const Footer = (props) => {
    return (
        <footer id="footer" className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-7"> 
              
              
              
              <p className="adrs-hd">Disclaimer-We are an independent service center, proudly recognized as the largest and fastest-growing provider in the industry! Our dedicated team specializes in service, AMC, repair, and maintenance of RO systems, ensuring top-notch care for your water purification needs. Please note that we operate independently and have no affiliation with any other company. Your satisfaction is our priority, and we take full responsibility for all our services!</p>
            </div>
            <div className="col-md-5">
              
              <p className="copy-ryt"><span>Copyright © 2024 All right reserved. &nbsp;&nbsp; | &nbsp;&nbsp; <a href="/privacy-policies" target="_blank">Privacy Policy</a></span></p>
            </div>
          </div>
        </div>
      </footer>
    )
}