import { useState, useEffect } from "react";
import { Route, Routes } from 'react-router-dom';

import JsonData from "./data/data.json";



import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Navigation } from "./components/navigation";
import Home from "./Home";

import { Footer } from "./components/footer";
import Footerdetail from "./components/Footerdetail";

import { Returnypolicy } from "./components/pages/returnpolicy";
import { TermsCondition } from "./components/pages/termscondition";
import { Faqp } from "./components/pages/faqp";
import { Privacypolicy } from "./components/pages/privacypolicy";
import { Helpcenter } from "./components/pages/helpcenter";
import Crous from './components/crous'
import { Wedo } from "./components/wedo";
import { About } from "./components/about";
import { Achivements } from "./components/achivements";
import { Amc } from "./components/amc";
import { Footern } from "./components/footern";
import { PrivacyPolicies2 } from "./components/privacypolicy2";
import { PageNotFound } from "./pageNotFound"

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});


const App = () => {


  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
    <div className="social">
    <div className="pop">
      <a className="phonescreen" href="tel:9912300212">
        <img src="img/banners/hycall2.png" alt="callus@9912300212" />
      </a>
    </div>
  </div>
      <Navigation />
    
      <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/privacy-policies" element={<PrivacyPolicies2 />} />
      <Route path="*" element={<PageNotFound />} />
      </Routes>
      
      <Footern />
      <Footer />
      
    </div>

  );
};


export default App;

