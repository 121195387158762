export const Wedo = (props) => {
  return (
    <div id="wedocontent" className="content-ares">
      <div className="container-fluid">
        <section className="sec-container">
          <div className="row">
            <div className="col-sm-12 col-md-12 text-right card-content">


              <div className="col-sm-12 col-md-3 content-desc disk">
                <img className="img-responsive lazyloaded" src="img/newimg/1.png" title="ro-service" alt="ro-service" />
                <h2>RO Service</h2>
                <p>Hire Best &amp; Get Best! Hiring skilled and professionals ensure that your water purifier will receive proper care. As it has several complex spare parts thus unprofessional hand can damage it completely. We are offering RO purifier services at the best &amp; affordable price In PAN India. We have dedicated service engineers team who gives 100% satisfactory work. </p>
              </div>

              <div className="col-sm-12 col-md-3 content-desc disk">
                <img className="img-responsive lazyloaded" src="img/newimg/2.png" title="ro-service" alt="ro-service" />
                <h2>RO Installation</h2>
                <p>Drink Healthy &amp; Live Healthy! Installation of water purifier is important for healthy living and it needs an expert &amp; skilled hand and we are excellent in this and offer our service in PAN India. Buy the installation service at nearly NO Cost from here &amp; get the satisfactory service at your doorsteps. Contact us immediately &amp; be part of our happy customer.  </p>
              </div>

              <div className="col-sm-12 col-md-3 content-desc disk">
                <img className="img-responsive lazyloaded" src="img/newimg/3.png" title="ro-service" alt="ro-service" />
                <h2>RO AMC</h2>
                <p>RO SERVICE CENTER offers an Annual Maintenance Contract (AMC) for water purifiers to ensure consistent performance and longevity. Our AMC covers regular servicing, timely filter replacements, and quick troubleshooting, preventing costly repairs and ensuring your water remains safe to drink. With a focus on customer satisfaction and expert technicians, choosing RO SERVICE CENTER for AMC means peace of mind and reliable water purification all year round.</p>
              </div>

              <div className="col-sm-12 col-md-3 content-desc disk">
                <img className="img-responsive lazyloaded" src="img/newimg/4.png" title="ro-service" alt="ro-service" />
                <h2>RO Customer Support</h2>
                <p>RO SERVICE CENTER stands out with its exceptional customer support through personalized service, quick response times, and a dedicated team of experts ready to assist. Unlike many competitors, we prioritize clear communication, offering detailed consultations &amp; follow-ups to ensure complete satisfaction. Our commitment to addressing customer needs and providing reliable solutions sets us apart, making every interaction a positive experience.</p>
              </div>

            </div>
          </div>
        </section>

      </div>
    </div>
  );
};
