export const Achivements = (props) => {
    return (
      
      <div id="achivements" className="achivements-section">
      <div className="stats py-5">
    <div className="container py-md-5">
      <h3 className="heading-agileinfo text-center">Our <span>Achievements</span></h3>
      <span className="w3-line black"></span>
      <div className="row inner_w3l_agile_grids-1 pt-md-5 mt-4">
        <div className="col-lg-3 col-sm-6 w3layouts_stats_left w3_counter_grid"> <i className="fa fa-trophy mb-2"></i>
          <p><span className="counter">20</span><span> Lac +</span></p>
          <h3>Happy Customer</h3>
        </div>
        <div className="col-lg-3 col-sm-6 w3layouts_stats_left w3_counter_grid1"> <i className="fa fa-graduation-cap mb-2"></i>
          <p><span className="counter">650</span><span> + </span></p>
          <h3>Cities</h3>
        </div>
        <div className="col-lg-3 col-sm-6 w3layouts_stats_left w3_counter_grid2"> <i className="fa fa-user mb-2"></i>
          <p><span className="counter">3500</span><span> + </span></p>
          <h3>Authorised Partner</h3>
        </div>
        <div className="col-lg-3 col-sm-6 w3layouts_stats_left w3_counter_grid2"> <i className="fa fa-users mb-2"></i>
          <p><span className="counter">20</span><span> + </span></p>
          <h3>Brand Covered</h3>
        </div>
      </div>
    </div>
  </div>
      </div>
    );
  };
  